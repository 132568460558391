import { useEffect } from 'react'

import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'

import { decodeToken, useCredentials } from '@dentalux/security'

import { isPast } from 'date-fns'
import { LocalStorage } from 'src/@types/LocalStorage'
import { setBookingIds, setDefaultBookingContext, useBookingsDispatch } from 'src/components/providers/BookingsProvider'

export const validateUrl = (url: string): boolean => {
  if (!url) {
    return false
  }

  try {
    new URL(url)
    return true
  } catch (error) {
    return false
  }
}

export const useRedirectUrlHandling = ({ shouldRead }: { shouldRead: boolean }) => {
  const router = useRouter()

  const { token } = useCredentials()

  const search = useSearchParams()

  const bookingsDispatch = useBookingsDispatch()

  useEffect(() => {
    if (shouldRead && search.get(LocalStorage.RedirectUrl)) {
      const queryRedirectUrl = decodeURIComponent(search.get(LocalStorage.RedirectUrl))

      const queryRedirectUrlValid = validateUrl(queryRedirectUrl)

      const redirectUrl = queryRedirectUrlValid ? queryRedirectUrl : null

      if (redirectUrl && redirectUrl.includes('/appointments')) {
        const searchParams = new URLSearchParams(new URL(redirectUrl).search)

        const clinicReferenceId = searchParams.get('clinicReferenceId')
        const treatmentBundleReferenceId = searchParams.get('treatmentBundleReferenceId')

        if (clinicReferenceId) {
          bookingsDispatch(setDefaultBookingContext())

          bookingsDispatch(
            setBookingIds({
              clinicReferenceId,
              treatmentBundleReferenceId: treatmentBundleReferenceId || null,
            })
          )
        }
      }

      if (token) {
        const decodedToken = decodeToken(token)

        if (!decodedToken['partial'] && !isPast(decodedToken.exp * 1000) && queryRedirectUrlValid) {
          router.replace(redirectUrl)

          return
        }
      }

      localStorage.setItem(LocalStorage.RedirectUrl, redirectUrl)
    }
  }, [router.isReady])

  useEffect(() => {
    const persistedRedirectUrl = localStorage.getItem(LocalStorage.RedirectUrl)

    const persistedRedirectUrlValid = validateUrl(persistedRedirectUrl)

    const isCurrentUrl = (redirect: string) => {
      const currentUrl = new URL(window.location.href)

      const parsedUrl = new URL(redirect)

      const samePathname = currentUrl.pathname === parsedUrl.pathname

      const currentSearchParams = new URLSearchParams(currentUrl.search)
      const providedSearchParams = new URLSearchParams(parsedUrl.search)

      const currentParamsArray = Array.from(currentSearchParams.entries())
      const providedParamsArray = Array.from(providedSearchParams.entries())

      const sameQueryParams =
        currentParamsArray.every(
          ([key, value]) => providedSearchParams.has(key) && providedSearchParams.get(key) === value
        ) &&
        providedParamsArray.every(
          ([key, value]) => currentSearchParams.has(key) && currentSearchParams.get(key) === value
        )

      return samePathname && sameQueryParams
    }

    if (!persistedRedirectUrlValid) {
      localStorage.removeItem(LocalStorage.RedirectUrl)
    }

    if (persistedRedirectUrlValid && token) {
      const decodedToken = decodeToken(token)

      if (!decodedToken['partial'] && isCurrentUrl(persistedRedirectUrl)) {
        localStorage.removeItem(LocalStorage.RedirectUrl)
      }
    }
  })
}
