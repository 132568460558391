import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'
import format from 'date-fns-tz/format'
import addWeeks from 'date-fns/addWeeks'
import { CancellationType } from 'src/@types/Appointment'
import { AdditionalAppointmentHints, BookingCreationEvent } from 'src/@types/Booking'
import { CostPlan } from 'src/@types/CostPlan'
import { PaginatedResponse } from 'src/@types/Pagination'
import { TimeSlot, TimeSlotInput } from 'src/@types/TimeSlot'
import { Treatment, TreatmentCategory } from 'src/@types/Treatment'
import { Upsell } from 'src/@types/Upsell'

const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_ECHO_CORE_RECORD_URL,
})

addAuthInterceptors(http)

const formatToTimezone = ({ date, timeZone }: { date: string | Date; timeZone: string }) =>
  format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", { timeZone })

export const getCategorisedBookableTreatmentBundles = async ({
  personReferenceId,
  clinicReferenceId,
  ...params
}: {
  personReferenceId: string
  clinicReferenceId: string
  practitionerReferenceId?: string
}): Promise<TreatmentCategory[]> => {
  const { data } = await http.get(
    `/v1/external/persons/${personReferenceId}/clinics/${clinicReferenceId}/bookable-treatment-bundles/categorised`,
    { params }
  )

  return data
}

export const getBookableTreatmentBundles = async ({
  personReferenceId,
  clinicReferenceId,
  ...params
}: {
  personReferenceId: string
  clinicReferenceId: string
  practitionerReferenceId?: string
}): Promise<Treatment[]> => {
  const { data } = await http.get(
    `/v1/external/persons/${personReferenceId}/clinics/${clinicReferenceId}/bookable-treatment-bundles`,
    { params }
  )

  return data
}

export const getTreatmentBundle = async ({
  treatmentBundleReferenceId,
  clinicReferenceId,
}: {
  treatmentBundleReferenceId: string
  clinicReferenceId: string
}): Promise<Treatment> => {
  const { data } = await http.get(`/v1/public/bookable-treatment-bundles/${treatmentBundleReferenceId}`, {
    params: { clinicReferenceId },
  })

  return data
}

export const getUpsellsForBookableTreatmentBundle = async ({
  treatmentBundleReferenceId,
  clinicReferenceId,
  personReferenceId,
  timeZone = 'Europe/Berlin',
}: {
  clinicReferenceId: string
  treatmentBundleReferenceId: string
  timeZone?: string
  personReferenceId
}): Promise<Upsell[]> => {
  const { data } = await http.get(`/v1/public/bookable-treatment-bundles/${treatmentBundleReferenceId}/upsells`, {
    params: {
      clinicReferenceId,
      personReferenceId,
      end: formatToTimezone({ timeZone, date: addWeeks(new Date(), 6) }),
    },
  })

  return data
}

export const getCostPlans = async ({
  personReferenceId,
}: {
  personReferenceId: string
}): Promise<PaginatedResponse<CostPlan>> => {
  const { data } = await http.get(`/external/v1/persons/${personReferenceId}/cost-plans`)

  return data
}

export const getRescheduleAvailabilities = async ({
  timeSlot,
  personReferenceId,
  timeZone = 'Europe/Berlin',
}: {
  timeSlot: TimeSlotInput
  personReferenceId: string
  timeZone?: string
}): Promise<TimeSlot[]> => {
  const { appointmentBundleReferenceId, startDateTime, endDateTime } = timeSlot

  const { data } = await http.get(`/v1/external/persons/${personReferenceId}/time-slots`, {
    params: {
      appointmentBundleReferenceId,
      start: formatToTimezone({ timeZone, date: startDateTime }),
      end: formatToTimezone({ timeZone, date: endDateTime }),
    },
  })

  return data
}

export const getAvailabilities = async ({
  timeSlot,
  personReferenceId,
  timeZone = 'Europe/Berlin',
}: {
  timeSlot: TimeSlotInput
  personReferenceId: string
  timeZone?: string
}): Promise<TimeSlot[]> => {
  const { clinicReferenceId, treatmentBundleReferenceId, practitionerReferenceIds, startDateTime, endDateTime } =
    timeSlot

  const { data } = await http.get(`/v1/external/persons/${personReferenceId}/time-slots`, {
    params: {
      treatmentBundleReferenceId,
      clinicReferenceId,
      start: formatToTimezone({ timeZone, date: startDateTime }),
      end: formatToTimezone({ timeZone, date: endDateTime }),
      employeeReferenceIds: practitionerReferenceIds.join(','),
    },
  })

  return data
}

export const createBooking = async ({
  personReferenceId,
  practitionerReferenceId,
  start,
  timeZone = 'Europe/Berlin',
  ...body
}: {
  start: string
  treatmentBundleReferenceId: string
  practitionerReferenceId: string
  clinicReferenceId: string
  additionalAppointmentHints?: AdditionalAppointmentHints
  creationEvent?: BookingCreationEvent
  personReferenceId: string
  timeZone?: string
}) => {
  const { data } = await http.post(`/v1/external/persons/${personReferenceId}/appointment-bundles`, {
    ...body,
    employeeReferenceId: practitionerReferenceId,
    start: formatToTimezone({ timeZone, date: start }),
  })

  return data
}

export const rescheduleBooking = async ({
  personReferenceId,
  appointmentBundleReferenceId,
  start,
  timeZone = 'Europe/Berlin',
}: {
  personReferenceId: string
  appointmentBundleReferenceId: string
  start: string
  timeZone?: string
}) => {
  const { data } = await http.post(
    `/v1/external/persons/${personReferenceId}/appointment-bundles/${appointmentBundleReferenceId}/reschedule`,
    {
      start: formatToTimezone({ timeZone, date: start }),
    }
  )

  return data
}

export const cancelBooking = async ({
  personReferenceId,
  appointmentBundleReferenceId,
  ...body
}: {
  personReferenceId: string
  appointmentBundleReferenceId: string
  cancellationType: CancellationType
}) => {
  const { data } = await http.post(
    `/v1/external/persons/${personReferenceId}/appointment-bundles/${appointmentBundleReferenceId}/cancellations`,
    body,
    {
      params: { cancellationSource: 'HEALTH_ASSISTANT' },
    }
  )

  return data
}
