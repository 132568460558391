import { gql } from '@apollo/client'

export const PractitionerFragments = {
  Practitioner: gql`
    fragment Practitioner on Practitioner {
      referenceId
      name
      imageUrl
      active
      positions {
        role
        level
      }
      cvEntries {
        endDate
        startDate
        title
        type
      }
      clinicReferenceId
      spokenLanguageCodes
      gender
    }
  `,
  PractitionerRating: gql`
    fragment PractitionerRating on PractitionerRating {
      latestPersonalRatingForPractitionerCreationDate
      latestPersonalRatingForPractitioner
      countReviewsOfPractitioner
      avgScoreOfPractitioner
    }
  `,
}

export const PRACTITIONER_QUERY = gql`
  query PRACTITIONER($practitionerReferenceId: UUID!, $personReferenceId: UUID!, $withRating: Boolean!) {
    practitioner(practitionerReferenceId: $practitionerReferenceId) {
      ...Practitioner
      rating(personReferenceId: $personReferenceId) @include(if: $withRating) {
        ...PractitionerRating
      }
    }
  }
  ${PractitionerFragments.Practitioner}
  ${PractitionerFragments.PractitionerRating}
`
